import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "site-content" }
const _hoisted_2 = { class: "section-content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "single-room-content" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-8" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "window",
      title: "Downloads"
    }),
    _createVNode("main", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.downloads, (download, index) => {
                  return (_openBlock(), _createBlock("div", {
                    key: index,
                    class: "border border-danger p-2 mb-2"
                  }, [
                    _createVNode("h3", null, _toDisplayString(download.title), 1),
                    _createVNode("div", {
                      innerHTML: download.desc
                    }, null, 8, ["innerHTML"]),
                    (download.url)
                      ? (_openBlock(), _createBlock("p", _hoisted_7, [
                          _createVNode("a", {
                            target: "_blank",
                            href: download.url
                          }, [
                            (!download.urlImage && download.urlText)
                              ? (_openBlock(), _createBlock("span", _hoisted_8, _toDisplayString(download.urlText), 1))
                              : _createCommentVNode("", true),
                            (download.urlImage)
                              ? (_openBlock(), _createBlock("img", {
                                  key: 1,
                                  src: download.urlImage.file.url,
                                  alt: download.urlImage.title
                                }, null, 8, ["src", "alt"]))
                              : _createCommentVNode("", true)
                          ], 8, ["href"]),
                          (download.url2)
                            ? (_openBlock(), _createBlock("a", {
                                key: 0,
                                target: "_blank",
                                href: download.url2,
                                class: "ml-2"
                              }, [
                                (!download.urlImage2 && download.urlText2)
                                  ? (_openBlock(), _createBlock("span", _hoisted_9, _toDisplayString(download.urlText2), 1))
                                  : _createCommentVNode("", true),
                                (download.urlImage2)
                                  ? (_openBlock(), _createBlock("img", {
                                      key: 1,
                                      src: download.urlImage2.file.url,
                                      alt: download.urlImage2.title
                                    }, null, 8, ["src", "alt"]))
                                  : _createCommentVNode("", true)
                              ], 8, ["href"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (download.file)
                      ? (_openBlock(), _createBlock("p", _hoisted_10, [
                          _createVNode("a", {
                            href: download.file.file.url,
                            target: "_blank"
                          }, "Download " + _toDisplayString(download.file.title), 9, ["href"])
                        ]))
                      : _createCommentVNode("", true),
                    (download.file2)
                      ? (_openBlock(), _createBlock("p", _hoisted_11, [
                          _createVNode("a", {
                            href: download.file2.file.url,
                            target: "_blank"
                          }, "Download " + _toDisplayString(download.file2.title), 9, ["href"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}