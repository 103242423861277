
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import { getDownloads, ParsedItem, getContact } from '../lib/Contentful'

interface DownloadData {
  downloads: ParsedItem[];
  contact: ParsedItem;
  // location: string;
}

export default defineComponent({
  name: 'Downloads',
  data() {
    return {
      downloads: [],
      contact: null,
      // location: window.location.pathname.split("/")[1]
    }
  },
  components: {
    PageHeader
  },
  async mounted() {
    await this.getDownloads();
    // await this.getContacts(this.location)
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getDownloads() {
      this.downloads = await getDownloads()
    },
    async getContacts(location: string) {
      this.contact = getContact(location)
    }
  }
});
